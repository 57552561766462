import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import PicsGrid from "../components/PicsGrid/PicsGrid";
import Description from "../components/Description/Description";

export const ecommerceImage = graphql`
  fragment ecommerceImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "ecommerce/hero.jpg" }) {
      ...ecommerceImage
    }
    image1: file(relativePath: { eq: "ecommerce/THE-ATTICO_1449.jpg" }) {
      ...ecommerceImage
    }
    image2: file(relativePath: { eq: "ecommerce/THE-ATTICO_1353.jpg" }) {
      ...ecommerceImage
    }
    image3: file(relativePath: { eq: "ecommerce/THE-ATTICO_1235.jpg" }) {
      ...ecommerceImage
    }
    image4: file(relativePath: { eq: "ecommerce/THE-ATTICO_2843.jpg" }) {
      ...ecommerceImage
    }
    image5: file(relativePath: { eq: "ecommerce/THE-ATTICO_2920.jpg" }) {
      ...ecommerceImage
    }
    image6: file(relativePath: { eq: "ecommerce/THE-ATTICO_2989.jpg" }) {
      ...ecommerceImage
    }
    image7: file(relativePath: { eq: "ecommerce/W0BD0BWCUD0-P68G.jpg" }) {
      ...ecommerceImage
    }
    image8: file(relativePath: { eq: "ecommerce/W0YK40D42J1-HSTA.jpg" }) {
      ...ecommerceImage
    }
    image9: file(relativePath: { eq: "ecommerce/0BG7955593Z-F86H2.jpg" }) {
      ...ecommerceImage
    }
    image10: file(relativePath: { eq: "ecommerce/360-1.jpg" }) {
      ...ecommerceImage
    }
    image11: file(relativePath: { eq: "ecommerce/360-2.jpg" }) {
      ...ecommerceImage
    }
    image12: file(relativePath: { eq: "ecommerce/360-3.jpg" }) {
      ...ecommerceImage
    }
  }
`;

const WearingArticle = () => (
  <>
    <span>
      Telling stories through images is what we do. Even when it comes to
      professional shoots designed specifically for the e-commerce world.
      Parallel Milano can offer a team of professionals made up of
      photographers, stylists, make-up artists, hair stylists and post producers
      capable of responding with speed, stylistic consistency, precision and
      quality.
    </span>
    <h4>Models</h4>
    <p>
      Clothes and accessories come to life on the bodies of professional models,
      enhancing all the product characteristics. The shoots, refined and
      contemporary, are optimized to ensure maximum uniformity within the site.
    </p>
  </>
);

const StillLifeArticle = () => (
  <>
    <h4>Still Life</h4>
    <p>
      Our Still Life shoots highlight the product quality in every single
      detail. Taken on neutral or customized backgrounds, they guarantee all the
      quality and speed of a digital production completed entirely in Italy.
    </p>
  </>
);

const GhostArticle = () => (
  <>
    <h4>Ghost Mannequin</h4>
    <p>
      The quality of our Ghost Mannequin effect photos is confirmed by an
      extreme attention to detail. This is the best shoot to valorise the
      product with the three-dimensionality help.
    </p>
  </>
);

const Still360Article = () => (
  <>
    <h4>Still life 360</h4>
    <p>
      Photos, of course, but also videos and special interactive tools. Our
      Still Life images can be rotated by 360 °: they are taken with the most
      sophisticated technology, showing the product in its entirety to elevate
      every single detail.
    </p>
  </>
);

const Ecommerce = ({ data }) => (
  <Layout pageUrl="ecommerce">
    <SEO title="E-commerce" />
    <Hero
      home={false}
      pageTitle="E-commerce"
      heroImage={data.heroImage.childImageSharp.fluid}
      heroAlt="E-commerce hero"
    />
    <section id="wearing">
      <PicsGrid
        pic1={data.image1.childImageSharp.fluid}
        alt1="Wearings showcase"
        pic2={data.image2.childImageSharp.fluid}
        alt2="Wearings showcase"
        pic3={data.image3.childImageSharp.fluid}
        alt3="Wearings showcase"
      />
      <Description
        heading="A contemporary approach to the online world"
        descriptionArticle={<WearingArticle />}
      />
    </section>
    <section id="still=life">
      <PicsGrid
        pic1={data.image4.childImageSharp.fluid}
        alt1="Still life showcase"
        pic2={data.image5.childImageSharp.fluid}
        alt2="Still life showcase"
        pic3={data.image6.childImageSharp.fluid}
        alt3="Still life showcase"
      />
      <Description
        heading="The product essence in one photo"
        descriptionArticle={<StillLifeArticle />}
      />
    </section>
    <section id="ghost">
      <PicsGrid
        hasGap
        pic1={data.image7.childImageSharp.fluid}
        alt1="Ghost showcase"
        pic2={data.image8.childImageSharp.fluid}
        alt2="Ghost showcase"
        pic3={data.image9.childImageSharp.fluid}
        alt3="Ghost showcase"
      />
      <Description
        heading="Volume and fitting in a single image"
        descriptionArticle={<GhostArticle />}
      />
    </section>
    <section id="still360">
      <PicsGrid
        hasGap
        pic1={data.image10.childImageSharp.fluid}
        alt1="Still 360 showcase"
        pic2={data.image11.childImageSharp.fluid}
        alt2="Still 360 showcase"
        pic3={data.image12.childImageSharp.fluid}
        alt3="Still 360 showcase"
      />
      <Description
        heading="The spinning image can elevate every detail"
        descriptionArticle={<Still360Article />}
      />
    </section>
  </Layout>
);

export default Ecommerce;
